<template>
  <div class="flex flex-col h-full justify-between">
    <div
      v-if="isModalLoading"
      class="fixed inset-0 bg-white z-50 bg-opacity-75 transition-opacity rounded-lg"
    >
      <div class="h-full flex items-center justify-center">
        <brightbid-loader size="120">
          <p>Loading...</p>
        </brightbid-loader>
      </div>
    </div>
    <form>
      <div class="p-8 flex flex-col gap-6">
        <p
          v-if="offeringId"
          class="h3"
        >
          Your offering
        </p>
        <p
          v-else
          class="h3"
        >
          Add offering
        </p>
        <text-input
          v-model="offeringForm.name"
          :disabled="isModalLoading"
          class="w-full"
          label="Product/Service Name"
          :placeholder="isModalLoading ? 'Loading...' : 'Name'"
          input-type="text"
          input-name="name"
          input-id="name"
          :error-message="$v.offeringForm.name.$error ? 'Please enter a name' : null"
          @focusout="$v.offeringForm.name.$touch"
        />

        <text-area
          v-model="offeringForm.description"
          :disabled="isModalLoading"
          class="w-full"
          label="Product/Service Description"
          input-type="text"
          input-name="description"
          input-id="description"
          :placeholder="isModalLoading ? 'Loading...' : 'Description'"
          :rows="4"
          :min-length="25"
          :max-length="150"
          :error-message="$v.offeringForm.description.$error ? offeringDescriptionErrorMessage : null"
          @focusout="$v.offeringForm.description.$touch"
        />

        <multi-select-search-input
          ref="multiSelectSearchInput"
          v-model="offeringForm.adgroups"
          :disabled="isModalLoading"
          input-name="select-ad-group"
          input-id="select-ad-group"
          chip-variant="squared"
          sub-label="Associate your offering with one or more ad groups. Use commas or press enter to separate items."
          :placeholder="isModalLoading ? 'Loading...' : 'Link Ad Group'"
          :error-message="$v.offeringForm.adgroups.$error ? 'At least one ad group is required' : null"
          :grouped="true"
          :options="groupedAdgroups"
          options-max-width="w-full"
          @update-chips="updateAdGroupList"
        >
          <template #icon>
            <ic-chain class="text-bb-disabled-gray" />
          </template>
        </multi-select-search-input>
      </div>
    </form>
    <div class="flex justify-between items-center m-10">
      <merge-button-round
        :disabled="isModalLoading"
        button-type="secondary"
        class="w-40"
        @click="$emit('close')"
      >
        <p>Cancel</p>
      </merge-button-round>
      <merge-button-round
        :disabled="isModalLoading"
        button-type="primary"
        class="w-40"
        @click="save"
      >
        <p>Save</p>
      </merge-button-round>
    </div>
  </div>
</template>

<script>
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import TextInput from '@/components/input/brightbid/TextInput'
import TextArea from '@/components/input/brightbid/TextArea'
import { cloneDeep } from 'lodash'
import MultiSelectSearchInput from '@/components/input/brightbid/MultiSelectSearchInput.vue'
import IcChain from 'vue-material-design-icons/Link'
import { mapActions, mapGetters } from 'vuex'
import BrightbidLoader from '@/components/loader/BrightbidLoader'

export default {
  components: {
    TextInput,
    TextArea,
    MultiSelectSearchInput,
    IcChain,
    BrightbidLoader,
  },
  props: {
    siteId: {
      type: Number,
      required: true,
    },
    offeringId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isModalLoading: false,
      offeringForm: {
        name: '',
        description: '',
        adgroups: [],
      },
      inputLengths: {
        description: {
          min: 25,
          max: 150,
        },
      },
    }
  },
  computed: {
    ...mapGetters('offerings', ['adgroups', 'offering']),
    groupedAdgroups() {
      const grouped = this.adgroups.reduce((groups, adgroup) => {
        const key = adgroup.subLabel
        if (!groups[key]) {
          groups[key] = []
        }
        groups[key].push(adgroup)
        return groups
      }, {})

      return Object.entries(grouped).map(([label, options]) => ({
        label,
        options,
      }))
    },
    offeringDescriptionErrorMessage() {
      const lengths = this.inputLengths.description
      if (!this.$v.offeringForm.description.$dirty) return null
      if (!this.$v.offeringForm.description.required) return 'Description is required'
      if (!this.$v.offeringForm.description.minLength) return `Please use at least ${lengths.min} characters.`
      if (!this.$v.offeringForm.description.maxLength) return `Please use at most ${lengths.max} characters.`
      return null
    },
  },
  async created() {
    this.isModalLoading = true
    await this.fetchAdgroups(this.siteId)
    if (this.offeringId) {
      await this.fetchOffering({ siteId: this.siteId, offeringId: this.offeringId })
      this.offeringForm = cloneDeep(this.offering)
      for (const adgroup of this.offering.adgroups) {
        this.$refs.multiSelectSearchInput.appendToChipList(adgroup)
      }
    }
    this.isModalLoading = false
  },
  validations() {
    const lengths = this.inputLengths.description
    return {
      offeringForm: {
        name: { required },
        description: {
          required,
          minLength: minLength(lengths.min),
          maxLength: maxLength(lengths.max),
        },
        adgroups: {
          required,
        },
      },
    }
  },
  methods: {
    ...mapActions('offerings', ['fetchAdgroups', 'fetchOffering']),
    save() {
      this.isModalLoading = true
      this.$v.offeringForm.$touch()
      if (this.$v.offeringForm.$invalid) {
        this.isModalLoading = false
        return
      }
      this.$emit('save', this.offeringForm)
      this.isModalLoading = false
    },
    updateAdGroupList(data) {
      this.offeringForm.adgroups = data
    },
  },
}
</script>
