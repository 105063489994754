<template>
  <tbody>
    <tr v-if="filteredData.length === 0">
      <td colspan="13">
        <div class="mx-auto my-20 flex flex-col items-center justify-center">
          <img
            class="w-24 h-auth"
            src="/content/images/illustrations/binoculars.png"
            alt="dashboard"
          />
          <p class="my-2 w-100 text-center">No campaigns found...</p>
        </div>
      </td>
    </tr>
    <tr
      v-for="(row, index) in filteredData"
      :key="row.id"
      :class="{ 'bg-neutral-0': index % 2 === 0, 'bg-white': index % 2 !== 0 }"
      class="h-12 whitespace-no-wrap w-full"
    >
      <td
        v-for="(value, key) in row.visibleFields"
        :key="key"
        class="px-5 py-2"
      >
        <slider-switch
          v-if="key === 'managed'"
          :id="row.id"
          type="managed"
          :status="value"
          @update-status="toggleManaged"
        />
        <span v-else-if="key === 'campaign_name'">{{ value }}</span>
        <radio-button-v2
          v-if="key === 'campaign_label'"
          :size="16"
          :is-selected="row.visibleFields.campaign_label === 'GENERIC'"
          @click="selectCampaignLabel(row.id, 'GENERIC')"
        />
      </td>
      <td class="px-5 py-2">
        <radio-button-v2
          :size="16"
          :is-selected="row.visibleFields.campaign_label === 'BRAND'"
          @click="selectCampaignLabel(row.id, 'BRAND')"
        />
      </td>
      <td class="px-5 py-2">
        <radio-button-v2
          :size="16"
          :is-selected="row.visibleFields.campaign_label === 'COMPETITOR'"
          @click="selectCampaignLabel(row.id, 'COMPETITOR')"
        />
      </td>
    </tr>
  </tbody>
</template>

<script>
// Components
import SliderSwitch from '@/components/input/brightbid/SliderSwitch.vue'
import RadioButtonV2 from '@/components/input/brightbid/RadioButtonV2.vue'

export default {
  name: 'CampaignSettingsRows',
  components: { SliderSwitch, RadioButtonV2 },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      managedStatus: {},
      campaignLabels: {},
    }
  },
  computed: {
    filteredData() {
      return this.data.map((row, index) => ({
        ...row,
        visibleFields: {
          campaign_name: row.campaign_name,
          managed: this.managedStatus[row.id] || (row.brightbid_managed ? 'ENABLED' : 'DISABLED'),
          campaign_label: this.campaignLabels[row.id] || row.campaign_label,
        },
        index: index,
      }))
    },
  },
  methods: {
    toggleManaged(id, type, newStatus) {
      if (type === 'managed') {
        this.$set(this.managedStatus, id, newStatus)
        this.$emit('toggle-managed', { id, newStatus })
      }
    },
    selectCampaignLabel(id, type) {
      this.$set(this.campaignLabels, id, type)
      this.$emit('select-campaign-label', { id, type })
    },
  },
}
</script>
